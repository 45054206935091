import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  IWixStyleFont,
  IStyleParam,
  IStyleParamValuesByType,
  createStylesParam,
} from '@wix/yoshi-flow-editor/tpa-settings';
import { Alignment } from '../../utils/alignment';
import { ISettingsParamsDefaults } from './defaultParams';
import { ButtonStyle } from './settingsParams';

export type StyleParam<T extends StyleParamType> = IStyleParam<T, IStyleParamValuesByType[T]>;
export type PackagePickerStylesParams = ReturnType<typeof createStylesParamsWithDefaults>;

const buttons = {
  buttonEmptyCorneredFontStyle: createStylesParam('empty-cornered-font-style', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  buttonEmptyCorneredWidth: createStylesParam('empty-cornered-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 262,
  }),
  buttonEmptyCorneredAlignment: createStylesParam('empty-cornered-alignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.CENTER,
  }),
  buttonEmptyCorneredFontColor: createStylesParam('empty-cornered-font-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  buttonEmptyCorneredBorderWidth: createStylesParam('empty-cornered-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  buttonEmptyCorneredCornerRadius: createStylesParam('empty-cornered-corner-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  buttonEmptyCorneredBorderOpacityAndColor: createStylesParam('empty-cornered-border-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  buttonEmptyCorneredBackground: createStylesParam('empty-cornered-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),

  buttonEmptyRoundedFontStyle: createStylesParam('empty-rounded-font-style', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  buttonEmptyRoundedWidth: createStylesParam('empty-rounded-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 262,
  }),
  buttonEmptyRoundedAlignment: createStylesParam('empty-rounded-alignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.CENTER,
  }),
  buttonEmptyRoundedFontColor: createStylesParam('empty-rounded-font-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  buttonEmptyRoundedBorderWidth: createStylesParam('empty-rounded-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  buttonEmptyRoundedCornerRadius: createStylesParam('empty-rounded-corner-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  }),
  buttonEmptyRoundedBorderOpacityAndColor: createStylesParam('empty-rounded-border-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  buttonEmptyRoundedBackground: createStylesParam('empty-rounded-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),

  buttonFullCorneredFontStyle: createStylesParam('full-cornered-font-style', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  buttonFullCorneredWidth: createStylesParam('full-cornered-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 262,
  }),
  buttonFullCorneredAlignment: createStylesParam('full-cornered-alignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.CENTER,
  }),
  buttonFullCorneredFontColor: createStylesParam('full-cornered-font-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  buttonFullCorneredBorderOpacityAndColor: createStylesParam('full-cornered-border-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),
  buttonFullCorneredBorderWidth: createStylesParam('full-cornered-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  buttonFullCorneredCornerRadius: createStylesParam('full-cornered-corner-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  buttonFullCorneredBackground: createStylesParam('full-cornered-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),

  buttonFullRoundedFontStyle: createStylesParam('full-rounded-font-style', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  buttonFullRoundedWidth: createStylesParam('full-rounded-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 262,
  }),
  buttonFullRoundedAlignment: createStylesParam('full-rounded-alignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.CENTER,
  }),
  buttonFullRoundedFontColor: createStylesParam('full-rounded-font-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  buttonFullRoundedBorderOpacityAndColor: createStylesParam('full-rounded-border-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),
  buttonFullRoundedBorderWidth: createStylesParam('full-rounded-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  buttonFullRoundedCornerRadius: createStylesParam('full-rounded-corner-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  }),
  buttonFullRoundedBackground: createStylesParam('full-rounded-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
};

export function createStylesParamsWithDefaults(defaults: ISettingsParamsDefaults = {}) {
  return {
    textAlignment: createStylesParam('text-alignment', {
      type: StyleParamType.Number,
      getDefaultValue: () => Alignment.CENTER,
    }),
    titleAlignment: createStylesParam('title-alignment', {
      type: StyleParamType.Number,
      getDefaultValue: () => Alignment.CENTER,
    }),
    subtitleAlignment: createStylesParam('subtitle-alignment', {
      type: StyleParamType.Number,
      getDefaultValue: () => Alignment.CENTER,
    }),
    disclaimerAlignment: createStylesParam('disclaimer-alignment', {
      type: StyleParamType.Number,
      getDefaultValue: () => Alignment.CENTER,
    }),
    descriptionAlignment: createStylesParam('description-alignment', {
      type: StyleParamType.Number,
      getDefaultValue: (params) => {
        const alignment = params.getStyleParamValue({ type: StyleParamType.Number, key: 'textAlignment' });
        return alignment ?? Alignment.CENTER;
      },
    }),
    benefitsAlignment: createStylesParam('benfits-alignment', {
      type: StyleParamType.Number,
      getDefaultValue: (params) => {
        const alignment = params.getStyleParamValue({ type: StyleParamType.Number, key: 'textAlignment' });
        return alignment ?? Alignment.CENTER;
      },
    }),
    planCardsSpacing: createStylesParam('plan-cards-spacing', {
      type: StyleParamType.Number,
      getDefaultValue: () => 32,
    }),
    buttonStyle: createStylesParam('button-style', {
      type: StyleParamType.Number,
      getDefaultValue: () => ButtonStyle.FULL_CORNERED,
    }),

    backgroundColor: createStylesParam('app-bg-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-1'),
    }),
    titleBackgroundColor: createStylesParam('plan-top-bg-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-1', 0),
    }),
    titleBackgroundColorHighlighted: createStylesParam('primary-top-base-bg-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8', 0.1),
    }),
    planBenefitBackgroundColor: createStylesParam('plan-benefits-bg-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-1', 0),
    }),
    regularBenefitBulletColor: createStylesParam('regular-benefits-bullet-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    }),
    highlightedBenefitBulletColor: createStylesParam('highlighted-benefits-bullet-color', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'regular-benefits-bullet-color' });
        return color ?? wixColorParam('color-8')(params);
      },
    }),
    planBenefitBackgroundColorHighlighted: createStylesParam('primary-benefits-base-bg-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-1', 0),
    }),
    cardBorderCornerRadius: createStylesParam('card-border-corner-radius', {
      type: StyleParamType.Number,
      getDefaultValue: () => 0,
    }),
    cardBorderWidth: createStylesParam('card-border-width', {
      type: StyleParamType.Number,
      getDefaultValue: () => 1,
    }),
    cardBorderColor: createStylesParam('card-border-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5', 0.2),
    }),
    regularCardShadowAngle: createStylesParam('regular-card-shadow-angle', {
      type: StyleParamType.Number,
      getDefaultValue: () => 135,
    }),
    regularCardShadowDistance: createStylesParam('regular-card-shadow-distance', {
      type: StyleParamType.Number,
      getDefaultValue: () => 0,
    }),
    regularCardShadowBlur: createStylesParam('regular-card-shadow-blur', {
      type: StyleParamType.Number,
      getDefaultValue: () => 25,
    }),
    regularCardShadowColor: createStylesParam('regular-card-shadow-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5', 0.15),
    }),
    highlightedCardShadowAngle: createStylesParam('highlighted-card-shadow-angle', {
      type: StyleParamType.Number,
      getDefaultValue: ({ getStyleParamValue }) => {
        const angle = getStyleParamValue({ type: StyleParamType.Number, key: 'regular-card-shadow-angle' });
        return angle ?? 135;
      },
    }),
    highlightedCardShadowDistance: createStylesParam('highlighted-card-shadow-distance', {
      type: StyleParamType.Number,
      getDefaultValue: ({ getStyleParamValue }) => {
        const distance = getStyleParamValue({ type: StyleParamType.Number, key: 'regular-card-shadow-distance' });
        return distance ?? 0;
      },
    }),
    highlightedCardShadowBlur: createStylesParam('highlighted-card-shadow-blur', {
      type: StyleParamType.Number,
      getDefaultValue: ({ getStyleParamValue }) => {
        const blur = getStyleParamValue({ type: StyleParamType.Number, key: 'regular-card-shadow-blur' });
        return blur ?? 25;
      },
    }),
    highlightedCardShadowColor: createStylesParam('highlighted-card-shadow-color', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'regular-card-shadow-color' });
        return color ?? wixColorParam('color-5', 0.15)(params);
      },
    }),
    cardBorderColorHighlighted: createStylesParam('card-border-color-highlighted', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'card-border-color' });
        return color || wixColorParam('color-5', 0.2)(params);
      },
    }),
    dividerWidth: createStylesParam('divider-width', {
      type: StyleParamType.Number,
      getDefaultValue: ({ getStyleParamValue }) =>
        getStyleParamValue({ type: StyleParamType.Boolean, key: 'show-benefits-dividers' }) ? 1 : 0,
    }),
    dividerColor: createStylesParam('divider-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5', 0.2),
    }),
    dividerColorHighlighted: createStylesParam('divider-color-highlighted', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5', 0.2),
    }),
    pageHeaderFont: createStylesParam('page-header-font', {
      type: StyleParamType.Font,
      getDefaultValue: wixFontParam('Page-title'),
    }),
    pageHeaderMobileFontSize: createStylesParam('page-header-mobile-font-size', {
      type: StyleParamType.Number,
      getDefaultValue: () => 20,
    }),
    pageHeaderColor: createStylesParam('page-header-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    }),
    pageSubtitleFont: createStylesParam('page-subtitle-font', {
      type: StyleParamType.Font,
      getDefaultValue: wixFontParam('Body-M', { size: 28 }),
    }),
    pageSubtitleMobileFontSize: createStylesParam('page-subtitle-mobile-font-size', {
      type: StyleParamType.Number,
      getDefaultValue: () => 14,
    }),
    pageSubtitleColor: createStylesParam('page-subtitle-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    }),
    pageDisclaimerFont: createStylesParam('page-disclaimer-font', {
      type: StyleParamType.Font,
      getDefaultValue: wixFontParam('Body-M', { size: 14 }),
    }),
    pageDisclaimerMobileFontSize: createStylesParam('page-disclaimer-mobile-font-size', {
      type: StyleParamType.Number,
      getDefaultValue: () => 14,
    }),
    pageDisclaimerColor: createStylesParam('page-disclaimer-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    }),
    benefitsSpacing: createStylesParam('benefits-spacing', {
      type: StyleParamType.Number,
      getDefaultValue: () => 1.5,
    }),
    horizontalPadding: createStylesParam('horizontal-padding', {
      type: StyleParamType.Number,
      getDefaultValue: () => 30,
    }),
    verticalPadding: createStylesParam('vertical-padding', {
      type: StyleParamType.Number,
      getDefaultValue: () => 30,
    }),
    planTitleFont: createStylesParam('plan-title-font', {
      type: StyleParamType.Font,
      getDefaultValue: wixFontParam('Body-M', {
        size: 20,
      }),
    }),
    planTitleMobileFontSize: createStylesParam('plan-title-mobile-font-size', {
      type: StyleParamType.Number,
      getDefaultValue: () => 20,
    }),
    planTitleColor: createStylesParam('plan-title-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    }),
    planPriceFont: createStylesParam('plan-price-font', {
      type: StyleParamType.Font,
      getDefaultValue: ({ textPresets }) => {
        const { value: _, ...font } = wixFontParam('Body-M', { size: 70 })({ textPresets });
        return { ...font } as IWixStyleFont;
      },
    }),
    planPriceMobileFontSize: createStylesParam('plan-price-mobile-font-size', {
      type: StyleParamType.Number,
      getDefaultValue: () => 70,
    }),
    planPriceColor: createStylesParam('plan-price-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    }),
    planTaglineFont: createStylesParam('plan-tagline-font', {
      type: StyleParamType.Font,
      getDefaultValue: ({ textPresets }) => {
        const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
        return { ...font } as IWixStyleFont;
      },
    }),
    planTaglineColor: createStylesParam('plan-tagline-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    }),
    planBenefitsFont: createStylesParam('plan-benefits-font', {
      type: StyleParamType.Font,
      getDefaultValue: ({ textPresets }) => {
        const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
        return { ...font } as IWixStyleFont;
      },
    }),
    planBenefitsMobileFontSize: createStylesParam('plan-benefits-mobile-font-size', {
      type: StyleParamType.Number,
      getDefaultValue: () => 14,
    }),
    planBenefitsColor: createStylesParam('plan-benefits-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    }),
    planRecurringOptionsFont: createStylesParam('plan-recurring-options-font', {
      type: StyleParamType.Font,
      getDefaultValue: (params) => {
        const priceFont = params.getStyleParamValue({ type: StyleParamType.Font, key: 'plan-price-font' });
        const { value: _, ...font } = wixFontParam('Body-M', { size: 12 })(params);
        return priceFont || ({ ...font } as IWixStyleFont);
      },
    }),
    planRecurringOptionsColor: createStylesParam('plan-recurring-options-color', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'plan-price-color' });
        return color || wixColorParam('color-5')(params);
      },
    }),
    planDurationFont: createStylesParam('plan-duration-font', {
      type: StyleParamType.Font,
      getDefaultValue: (params) => {
        const taglineFont = params.getStyleParamValue({ type: StyleParamType.Font, key: 'plan-tagline-font' });
        const { value: _, ...font } = wixFontParam('Body-M', { size: 12 })(params);
        return taglineFont || ({ ...font } as IWixStyleFont);
      },
    }),
    planDurationColor: createStylesParam('plan-duration-color', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'plan-tagline-color' });
        return color || wixColorParam('color-5')(params);
      },
    }),
    showPageTitle: createStylesParam('show-page-title', {
      type: StyleParamType.Boolean,
      getDefaultValue: () => (defaults.showPageTitle !== undefined ? defaults.showPageTitle : true),
    }),
    showPlanName: createStylesParam('show-plan-name', {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    }),
    showTagline: createStylesParam('show-plan-tagline', {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    }),
    showBenefits: createStylesParam('show-plan-benefits', {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    }),
    showBenefitsDividers: createStylesParam('show-benefits-dividers', {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    }),
    showDisclaimer: createStylesParam('show-disclaimer', {
      type: StyleParamType.Boolean,
      getDefaultValue: () => false,
    }),
    showRibbon: createStylesParam('show-ribbon', {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    }),
    ribbonBackgroundColor: createStylesParam('ribbon-bg-opacity-and-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    }),
    ribbonFont: createStylesParam('ribbon-font', {
      type: StyleParamType.Font,
      getDefaultValue: ({ textPresets }) => {
        const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
        return { ...font } as IWixStyleFont;
      },
    }),
    ribbonColor: createStylesParam('ribbon-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-1'),
    }),
    ribbonBorderWidth: createStylesParam('ribbon-border-width', {
      type: StyleParamType.Number,
      getDefaultValue: () => 0,
    }),
    ribbonBorderColor: createStylesParam('ribbon-border-color', {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5', 0),
    }),
    planTitleColorHighlighted: createStylesParam('plan-title-color-highlighted', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'plan-title-color' });
        return color || wixColorParam('color-5')(params);
      },
    }),
    planPriceColorHighlighted: createStylesParam('plan-price-color-highlighted', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'plan-price-color' });
        return color || wixColorParam('color-5')(params);
      },
    }),
    planRecurringOptionsColorHighlighted: createStylesParam('plan-recurring-options-color-highlighted', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'plan-price-color' });
        return color || wixColorParam('color-5')(params);
      },
    }),
    planTaglineColorHighlighted: createStylesParam('plan-tagline-color-highlighted', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'plan-tagline-color' });
        return color || wixColorParam('color-5')(params);
      },
    }),
    planDurationColorHighlighted: createStylesParam('plan-duration-color-highlighted', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'plan-duration-color' });
        return color || wixColorParam('color-3')(params);
      },
    }),
    planBenefitsColorHighlighted: createStylesParam('plan-benefits-color-highlighted', {
      type: StyleParamType.Color,
      getDefaultValue: (params) => {
        const color = params.getStyleParamValue({ type: StyleParamType.Color, key: 'plan-benefits-color' });
        return color || wixColorParam('color-5')(params);
      },
    }),

    ...buttons,

    buttonEmptyCorneredFontColorHighlighted: deriveHighlighted(buttons.buttonEmptyCorneredFontColor),
    buttonEmptyCorneredCornerRadiusHighlighted: deriveHighlighted(buttons.buttonEmptyCorneredCornerRadius),
    buttonEmptyCorneredBorderOpacityAndColorHighlighted: deriveHighlighted(
      buttons.buttonEmptyCorneredBorderOpacityAndColor,
    ),
    buttonEmptyCorneredBackgroundHighlighted: deriveHighlighted(buttons.buttonEmptyCorneredBackground),

    buttonEmptyRoundedFontColorHighlighted: deriveHighlighted(buttons.buttonEmptyRoundedFontColor),
    buttonEmptyRoundedCornerRadiusHighlighted: deriveHighlighted(buttons.buttonEmptyRoundedCornerRadius),
    buttonEmptyRoundedBorderOpacityAndColorHighlighted: deriveHighlighted(
      buttons.buttonEmptyRoundedBorderOpacityAndColor,
    ),
    buttonEmptyRoundedBackgroundHighlighted: deriveHighlighted(buttons.buttonEmptyRoundedBackground),

    buttonFullCorneredFontColorHighlighted: deriveHighlighted(buttons.buttonFullCorneredFontColor),
    buttonFullCorneredCornerRadiusHighlighted: deriveHighlighted(buttons.buttonFullCorneredCornerRadius),
    buttonFullCorneredBorderOpacityAndColorHighlighted: deriveHighlighted(
      buttons.buttonFullCorneredBorderOpacityAndColor,
    ),
    buttonFullCorneredBackgroundHighlighted: deriveHighlighted(buttons.buttonFullCorneredBackground),

    buttonFullRoundedFontColorHighlighted: deriveHighlighted(buttons.buttonFullRoundedFontColor),
    buttonFullRoundedCornerRadiusHighlighted: deriveHighlighted(buttons.buttonFullRoundedCornerRadius),
    buttonFullRoundedBorderOpacityAndColorHighlighted: deriveHighlighted(
      buttons.buttonFullRoundedBorderOpacityAndColor,
    ),
    buttonFullRoundedBackgroundHighlighted: deriveHighlighted(buttons.buttonFullRoundedBackground),
  };
}

export default createStylesParamsWithDefaults();

function deriveHighlighted<T extends StyleParamType>(regular: StyleParam<T>): StyleParam<T> {
  return {
    ...regular,
    key: regular.key + '-highlighted',
    getDefaultValue: (params) =>
      params.getStyleParamValue(regular) || (regular.getDefaultValue && regular.getDefaultValue(params)),
  };
}
