import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { useEnvironment, useTranslation, useFedopsLogger } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { VIEWER_HOOKS } from '../../../../constants';
import { TabState } from '../../../../constants/settings-events';
import { useAdaptedSettings, useStylesParams } from '../../../../hooks';
import { PopupEnum, MessageCode } from '../../../../types/common';
import { alignmentName } from '../../../../utils/alignment';
import { messageToText, isMessageInformational } from '../../../../utils/errors';
import { Toast } from '../Toast';
import { st, classes } from './List.st.css';
import { PlanList, MAX_ITEM_WIDTH } from './PlanList';
import Popup from './Popup';

export interface ListProps {
  plans: PublicPlan[];
  tabState: TabState;
  areMobileSettingsOpened: boolean;
  popup: PopupEnum | null;
  interactionEnd: string;
  message?: MessageCode;
  dataHook?: string;
  selectPlan(plan: PublicPlan): void;
  hidePopup(): void;
  hideToast(): void;
  closeUpgradeModal(): void;
}

const List: React.FC<ListProps> = ({
  plans,
  tabState,
  areMobileSettingsOpened,
  selectPlan,
  popup,
  hidePopup,
  message,
  dataHook,
  hideToast,
  interactionEnd,
}) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();
  const fedops = useFedopsLogger();
  const stylesParams = useStylesParams();
  const settings = useAdaptedSettings();
  const styles = useStyles();

  const showPageTitle = styles.get(stylesParams.showPageTitle);
  const showPageSubtitle = settings.get('showPageSubtitle');
  const showHeader = showPageTitle || showPageSubtitle;
  const showDisclaimer = styles.get(stylesParams.showDisclaimer);

  const titleAlignment = alignmentName(styles.get(stylesParams.titleAlignment));
  const subtitleAlignment = alignmentName(styles.get(stylesParams.subtitleAlignment));
  const disclaimerAlignment = alignmentName(styles.get(stylesParams.disclaimerAlignment));

  React.useEffect(() => {
    fedops.interactionEnded(interactionEnd);
  }, []);

  const visiblePlanCount = settings.countVisiblePlans(plans);
  const extraPlanListPadding = 30;
  const maxWidthStyle =
    !isEditorX && visiblePlanCount ? { maxWidth: MAX_ITEM_WIDTH * visiblePlanCount - extraPlanListPadding } : {};
  return (
    <div className={st(classes.root, { mobile: !!isMobile })} data-hook={dataHook ?? 'PackagePicker-wrapper'}>
      {plans.length ? (
        <>
          {showHeader && (
            <div className={classes.header} style={maxWidthStyle}>
              {showPageTitle && (
                <h1 className={st(classes.title, { textAlignment: titleAlignment })} data-hook="app-title">
                  {settings.get('pageHeaderText') || t('pp.defaults.pageTitle')}
                </h1>
              )}
              {showPageSubtitle && (
                <p
                  className={st(classes.subtitle, { textAlignment: subtitleAlignment })}
                  data-hook={VIEWER_HOOKS.PP_WIDGET_SUBTITLE}
                >
                  {settings.get('pageSubtitleText') || t('pp.defaults.pageSubtitle')}
                </p>
              )}
            </div>
          )}
          {message ? (
            <Toast onClose={hideToast} informational={isMessageInformational(message)}>
              {t(messageToText(message))}
            </Toast>
          ) : null}
          <PlanList
            selectPlan={selectPlan}
            plans={plans}
            showDemoHighlight={tabState === TabState.HIGHLIGHTED}
            expandMobileBenefits={areMobileSettingsOpened}
          />
          {showDisclaimer && (
            <div
              className={st(classes.disclaimer, {
                textAlignment: disclaimerAlignment,
              })}
              style={maxWidthStyle}
            >
              <p>{settings.get('disclaimerText')}</p>
            </div>
          )}
        </>
      ) : (
        <h1 data-hook="empty-state-message" className={st(classes.empty)}>
          {t('pp.no-plans')}
        </h1>
      )}
      {popup ? <Popup popup={popup} hidePopup={hidePopup} /> : null}
    </div>
  );
};

export default List;
